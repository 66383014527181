@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

:root {
    --sb-col8-title-1: clamp(14px, 1.25vw, 18px);
}

@media (max-width: 767px) {
    :root {
        --sb-col8-title-1: 3.5vw;
    }
}

@media (max-width: 575px) {
    :root {
        --sb-col8-title-1: 4vw;
    }
}

/*--------------------------------------------------------------
# Line Clamp
--------------------------------------------------------------*/
.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}
.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
}


/*--------------------------------------------------------------
# Responsive font-size
--------------------------------------------------------------*/


/*Sidebar*/
.sidebar-title-col3-col8 {
    font-size: clamp(14px, 1.25vw, 18px);
}
@media (max-width: 767px) {
    .sidebar-title-col3-col8 {
        font-size: clamp(18px, 3.5vw, 30px);
    }
}

.sidebar-title-col3-col8-2 {
    font-size: clamp(14px, 1.25vw, 18px);
}
@media (max-width: 767px) {
    .sidebar-title-col3-col8-2 {
        font-size: clamp(14px, 2.5vw, 18px);
    }
}
@media (max-width: 575px) {
    .sidebar-title-col3-col8-2 {
        font-size: clamp(18px, 3.5vw, 30px);
    }
}

/*------------------------*/
/*Blog*/
/*------------------------*/
/*Col 3*/
/*------------------------*/
.blog-title-col3-col12-1 {
    font-size: clamp(16px, 1.75vw, 20px);
}
@media (max-width: 767px) {
    .blog-title-col3-col12-1 {
        font-size: clamp(16px, 4vw, 22px);
    }
}
/*------------------------------*/
.blog-title-col3-col12-2 {
    font-size: clamp(16px, 1.75vw, 20px);
}
@media (max-width: 767px) {
    .blog-title-col3-col12-2 {
        font-size: clamp(16px, 4vw, 22px);
    }
}
/*------------------------*/
/*Col 4*/
/*------------------------*/
.blog-title-col4-col12 {
    font-size: clamp(16px, 1.6vw, 20px);
}
@media (max-width: 767px) {
    .blog-title-col4-col12 {
        font-size: clamp(16px, 4vw, 20px);
    }
}
/*--------------------------*/
.blog-title-col4-col6-1 {
    font-size: clamp(16px, .9vw, 16px);
}
@media (max-width: 1199px) {
    .blog-title-col4-col6-1 {
        font-size: clamp(16px, 1.5vw, 22px);
    }
}
@media (max-width: 767px) {
    .blog-title-col4-col6-1 {
        font-size: clamp(16px, 4.25vw, 30px);
    }
}
/*------------------------------*/
.blog-title-col4-col6-2 {
    font-size: clamp(16px, 1.25vw, 18px);
}
@media (max-width: 991px) {
    .blog-title-col4-col6-2 {
        font-size: clamp(16px, 2.5vw, 24px);
    }
}
@media (max-width: 767px) {
    .blog-title-col4-col6-2 {
        font-size: clamp(16px, 4.25vw, 30px);
    }
}
/*---------------------------------*/
.blog-title-col4-col7-1 {
    font-size: clamp(16px, 1vw, 20px);
}
@media (max-width: 1199px) {
    .blog-title-col4-col7-1 {
        font-size: clamp(16px, 1.75vw, 20px);
    }
}
@media (max-width: 767px) {
    .blog-title-col4-col7-1 {
        font-size: clamp(16px, 2.5vw, 20px);
    }
}
@media (max-width: 575px) {
    .blog-title-col4-col7-1 {
        font-size: clamp(16px, 4.5vw, 24px);
    }
}
/*---------------------------------*/
.blog-title-col4-col7-2 {
    font-size: clamp(14px, 1.75vw, 20px);
}
@media (max-width: 767px) {
    .blog-title-col4-col7-2 {
        font-size: clamp(16px, 2.5vw, 20px);
    }
}
@media (max-width: 575px) {
    .blog-title-col4-col7-2 {
        font-size: clamp(16px, 4.5vw, 24px);
    }
}
/*---------------------------------*/
.blog-title-col4-col6_12-1 {
    font-size: clamp(16px, 1vw, 22px);
}
@media (max-width: 1199px) {
    .blog-title-col4-col6_12-1 {
        font-size: clamp(14px, 1.5vw, 22px);
    }
}
@media (max-width: 991px) {
    .blog-title-col4-col6_12-1 {
        font-size: clamp(16px, 1.75vw, 20px);
    }
}
@media (max-width: 767px) {
    .blog-title-col4-col6_12-1 {
        font-size: clamp(16px, 2.75vw, 20px);
    }
}
/*------------------------------*/
.blog-title-col4-col6_12-2 {
    font-size: clamp(16px, 1.25vw, 18px);
}
@media (max-width: 991px) {
    .blog-title-col4-col6_12-2 {
        font-size: clamp(16px, 2vw, 20px);
    }
}
@media (max-width: 767px) {
    .blog-title-col4-col6_12-2 {
        font-size: clamp(16px, 2.75vw, 20px);
    }
}
/*------------------------*/
/*Col 6*/
/*------------------------*/
.blog-title-col6-col12-1 {
    font-size: clamp(32px, 3.5vw, 40px);
}
@media (max-width: 767px) {
    .blog-title-col6-col12-1 {
        font-size: clamp(32px, 8vw, 44px);
    }
}
/*------------------------------*/
.blog-title-col6-col12-2 {
    font-size: clamp(32px, 3.5vw, 40px);
}
@media (max-width: 767px) {
    .blog-title-col6-col12-2 {
        font-size: clamp(32px, 8vw, 44px);
    }
}
/*------------------------*/
.blog-title-col6-col6-1 {
    font-size: clamp(16px, 1.75vw, 20px);
}
@media (max-width: 991px) {
    .blog-title-col6-col6-1 {
        font-size: clamp(24px, 3vw, 26px);
    }
}
@media (max-width: 767px) {
    .blog-title-col6-col6-1 {
        font-size: clamp(16px, 4vw, 26px);
    }
}
/*------------------------------*/
.blog-title-col6-col6-2 {
    font-size: clamp(16px, 1.75vw, 20px);
}
@media (max-width: 767px) {
    .blog-title-col6-col6-2 {
        font-size: clamp(16px, 4vw, 26px);
    }
}

/*--------------------------------------------------------------
# Bar
--------------------------------------------------------------*/
.bar-left::before {
    content: "";
    width: 2px;
    height: 10px;
    display: inline-block;
    background: red;
    margin: 0 8px;
}
.bar-top::before {
    content: "";
    width: 40px;
    height: 2px;
    display: block;
    background: red;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/


body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
}

a {
    text-decoration: none;
    color: #d9232d;
}

a:hover {
    color: #e24d55;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
}
.postTitle {
    font-weight: bold;
    padding: 0;
}
.postTitle a {
    color: #556270;
    transition: 0.3s;
}
.postTitle a:hover {
    color: #d9232d;
}
/*--------------------------------------------------------------
# Sections_render General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg, .services .icon-box {
    background-color: #f8f9fa;
}

.section-title {
    padding-bottom: 40px;
    text-align: left;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #e6636a;
    margin: 4px 10px;
}

.section-title p:nth-of-type(1) {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #556270;
}

.section-title p:nth-of-type(2) {
    margin: 0;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #aaaaaa;
    font-style: oblique;
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    width: 100%;
    background: #fff;
}

.contact .info i {
    font-size: 20px;
    color: #556270;
    float: left;
    width: 44px;
    height: 44px;
    background: #edeff1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #556270;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #8795a4;
}

.contact .info .email, .contact .info .phone {
    margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #556270;
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br + br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #d9232d;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    background: #d9232d;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #e24d55;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
    color: #556270;
    transition: 0.3s;
}

.blog .entry .entry-title a:hover {
    color: #d9232d;
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #c1c8d0;
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.blog .entry .entry-meta ul li + li {
    padding-left: 20px;
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
}

.blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.blog .entry .entry-content p {
    line-height: 24px;
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #d9232d;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
    background: #df3740;
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #556270;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
    color: #a4afba;
    display: inline;
}

.blog .entry .entry-footer a {
    color: #606f7e;
    transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
    color: #d9232d;
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.blog .entry .entry-footer .cats li {
    display: inline-block;
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.blog .entry .entry-footer .tags li {
    display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.blog .entry .entry-footer .share {
    font-size: 16px;
}

.blog .entry .entry-footer .share i {
    padding-left: 5px;
}

.blog .entry-single {
    margin-bottom: 30px;
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px;
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #556270;
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
    color: rgba(85, 98, 112, 0.5);
    margin-right: 5px;
}

.blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
}

.blog .blog-comments {
    margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
    font-weight: bold;
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
    width: 60px;
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
    color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #6b7b8d;
    margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.blog .blog-comments .reply-form p {
    font-size: 14px;
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #606f7e;
}

.blog .blog-pagination {
    color: #8795a4;
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
}

.blog .blog-pagination li a {
    color: #556270;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
    background: #d9232d;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
    color: #fff;
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #556270;
    position: relative;
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
}

.blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #d9232d;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
}

.blog .sidebar .search-form form button i {
    line-height: 0;
}

.blog .sidebar .search-form form button:hover {
    background: #de323c;
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .categories ul li + li {
    padding-top: 10px;
}

.blog .sidebar .categories ul a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
    color: #d9232d;
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
    margin-top: 15px;
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
    color: #556270;
    transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
    color: #d9232d;
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
}

.blog .sidebar .tags {
    margin-bottom: -10px;
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.blog .sidebar .tags ul li {
    display: inline-block;
}

.blog .sidebar .tags ul a {
    color: #96a2af;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid white;
    display: inline-block;
    transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #d9232d;
    background: #d9232d;
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #fbfbfc;
    font-size: 14px;
}

#portfolio {
    position: relative;
}

#portfolio .main > .tns-outer > .tns-nav {
    bottom: 11vh;
    position: absolute;
    z-index: 999;
}

#portfolio .main > .tns-outer > .tns-nav button {
    height: 2vw;
    width: 2vw;
    display: block;
    border-radius: 50%;
    padding: 0px;
    margin: 5vw;
    background: #556270;
    border: 2px solid #556270;

}

#portfolio .main > .tns-outer > .tns-nav button:hover {
    background: #444444;
    width: 5vw;
    border-radius: 20px;
    border: 2px solid #444444;
}

#portfolio .main > .tns-outer > .tns-nav-active {
    background: #de323c !important;
    border: 2px solid #de323c !important;
}

#portfolio .main > .tns-outer > .tns-nav-active:hover {
    background: #d9232d !important;
    border: 2px solid #d9232d !important;
}

#portfolio .main > .tns-outer > .tns-controls {
    left: 49vw;
    position: absolute;
    z-index: 9999;
}

#portfolio .main > .tns-outer > .tns-controls button {
    height: 4vh;
    width: 4vh;
    display: block;
    padding: 0px;
    border: 0px;
    border-radius: 50%;
}

#portfolio .main > .tns-outer > .tns-controls button:hover {
    background: #ffffff;
}

#portfolio .main > .tns-outer > .tns-controls button:nth-child(2) {
    margin-top: 72vh;
}


.divider-move-up {
    position: relative;
    bottom: 5.5vw;
}

.divider-overlap svg {
    position: absolute;
    z-index: 2;
}

*.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.waveDivider {
    background: #444444;
}

.divider-waves {
    display: block;
    width: 100%;
    height: 60px;
    /*background: linear-gradient(0deg, #2a2c39 0%, #33364a 100%);*/
    /*background: #444444;*/
    background: linear-gradient(90deg, #de323c 0%, #de323c 35%, #e9797f 100%);
}

.divider-waves svg {
    position: absolute;
    background: #444444;
}

.wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }
    100% {
        transform: translate(-90px, 0%);
    }
}

@-webkit-keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@-webkit-keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }
    100% {
        transform: translate(85px, 0%);
    }
}

.grid-item {
    width: 25%;
}

.grid-item--width2 {
    width: 50%;
}
